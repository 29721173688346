




























































import { Component, Vue, Prop } from "vue-property-decorator";
import cabezera_preguntaModule from "@/store/modules/cabezera_pregunta-module";
import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import { cabezera_pregunta } from "@/shared/dtos/cabezera_pregunta";
import { DxDataGrid, DxColumn, DxEditing } from "devextreme-vue/data-grid";
import { lineas_Pregunta } from "@/shared/dtos/lineas_Pregunta";
@Component({
  components: {
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue"),
    ConfiguracionFichaOpciones: () =>
      import("@/views/configuracion/configuracion-ficha-opciones.vue"),
    DxDataGrid,
    DxColumn,
    DxEditing
  }
})
export default class PreguntasConsultaConfiguracion extends Vue {
  public synceddialog: boolean = false;
  public cabecera_pregunta: cabezera_pregunta = new cabezera_pregunta();
  public created() {
    cabezera_preguntaModule.getcabezera_preguntascabezeras();
  }

  public get preguntas_cabezera() {
    return cabezera_preguntaModule.cabezera_preguntas;
  }

  public get Columns() {
    var Columnas: ClassColumnDataTable[] = [];
    Columnas.push(
      new ClassColumnDataTable(
        "pregunta",
        "Pregunta",
        datatypes.string,
        true,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "solo_una_respuesta",
        "Preguntar solo una vez",
        datatypes.boolean,
        true,
        datatypes.maxlength
      )
    );
    return Columnas;
  }
  public async crear_pregunta() {
    await cabezera_preguntaModule.guardarcabezera_pregunta(
      this.cabecera_pregunta
    );
    this.cabecera_pregunta = new cabezera_pregunta();
    cabezera_preguntaModule.getcabezera_preguntascabezeras();
    this.synceddialog = false;
  }
  public eliminarpregunta(id_pregunta: number) {
    cabezera_preguntaModule.eliminarcabezera_pregunta_id(id_pregunta);
  }
}
